import React from 'react'
import styled from 'styled-components'
import Hero from '../../../resuable/hero/hero'
import { dimensions } from '../../../../utils/styles/styles'

const StyledBlogGuidelines = styled.div`
  width: 100%;
  div.container__content {
    width: 100%;
    display: flex;
    justify-content: center;
    div.container__content-text {
      width: 100%;
      max-width: ${dimensions.siteWidth};
    }
  }
`

const BlogGuidelines = ({ title, content }) => {
  return (
    <StyledBlogGuidelines>
      <Hero
        title={title}
      />
      <div className="container__content">
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="container__content-text"
        />
      </div>
    </StyledBlogGuidelines>
  )
}

BlogGuidelines.defaultProps = {

}

export default BlogGuidelines