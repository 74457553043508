import React from 'react'
import { graphql } from 'gatsby'

import Layout from "../../components/resuable/layout/layout";
import SEO from "../../components/resuable/seo/seo"
import BlogGuidelines from '../../components/page/blog-components/blog-submit-page/blog-guidelines';
import Joinus from '../../components/resuable/joinus/joinus';

const Submit = ({ data }) => {
  const { title, content, yoast_meta } = data.wordpressPage;
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = yoast_meta;
  return (
    <Layout
      isBlogMenuActive={true}
    >
      <SEO 
        title={yoast_wpseo_title}
        description={yoast_wpseo_metadesc}
      />
      <BlogGuidelines 
        title={title}
        content={content}
      />
      <Joinus />
    </Layout>
  )
}

export const query = graphql`
  query blogGuidelesPage {
    wordpressPage(slug: {eq: "blog-guidelines"}) {
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`

export default Submit
